// 开发环境域名

const host_development = 'https://likeshopb2cplusdoukai.yixiangonline.com'

export default {
    // 版本
    version: '2.2.1',
    baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
    tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW',
}
